/**
 * *************************************************************************
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 * * *************************************************************************
 */

import React from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
// import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { parse } from 'query-string';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
// import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { addQuestionAction } from './logic'
import { makeId } from '../../utils'

class AddForm extends React.Component {
  constructor(props) {
    super(props);
    const parsed = parse(this.props.location.search);
    this.state = {
      quest: '',
      values: ['Information content is not helpful', 'Information is not easy to understand', 'Relevant information not provided'],
      app: parsed.app || '',
      cat: parsed.cat || '',
      subcat: parsed.subcat || '',
      editorStateAns: EditorState.createEmpty()
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.addClick = this.addClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.removeClick = this.removeClick.bind(this);
    this.handleChangeQuestion = this.handleChangeQuestion.bind(this);
  }
  onEditorStateAnsChange= (editorState) => {
    this.setState({
      editorStateAns: editorState,
    });
  };


  handleSubmit(event) {
    const textData = draftToHtml(convertToRaw(this.state.editorStateAns.getCurrentContent()));
    event.preventDefault();
    if (textData.length > 8) {
      const q = {
        app: this.state.app,
        category: this.state.cat,
        subcategory: this.state.subcat,
        question: this.state.quest,
        answer: draftToHtml(convertToRaw(this.state.editorStateAns.getCurrentContent())),
        parent_id: `${this.state.subcat}` ? `${makeId(this.state.app, this.state.cat, this.state.subcat)}` : `${makeId(this.state.app, this.state.cat)}`,
        ques_id: Math.random().toString(36).substr(5),
        feedback: this.state.values
      }
      this.props.addQuestionAction(q);
      this.props.changeState('addQuestionForm', false)
    }
  }
  handleChange(i, event) {
    const values = [...this.state.values];
    values[i] = event.target.value;
    this.setState({ values });
  }
  // this is for the the creating new input field
  createUI() {
    return this.state.values.map((el, i) =>
      (
        <div key={i} className="input-group modal-input">
          <input type="text" value={el || ''} onChange={e => this.handleChange(i, e)} placeholder='Enter here' className="form-control" required />
          <div className='input-group-btn'>
            <button className='btn remove-btn' onClick={() => this.removeClick(i)}>X</button>
          </div>
        </div>
      ))
  }
  addClick() {
    this.setState(prevState => ({ values: [...prevState.values, ''] }))
  }
  removeClick(i) {
    const values = [...this.state.values];
    values.splice(i, 1);
    this.setState({ values });
  }

  handleChangeQuestion(event) {
    this.setState({ quest: event.target.value });
  }

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <input type='hidden' value={this.state.app} className="form-control" required />
          <input type='hidden' value={this.state.cat} className="form-control" required />
          {this.state.subcat &&
          <input type='hidden' value={this.state.subcat} className="form-control" required />
          }
          <div className="row">
            <div className="form-group col-md-12 modal-input">
              <input type="submit" value="SAVE" className="primary-button right" />
              <button className='cancel-btn btn-margin right' onClick={() => this.props.changeState('addQuestionForm', false)} >Cancel</button>
              <label>Question *</label>
              <input type="text" value={this.state.quest} onChange={this.handleChangeQuestion} className="form-control" placeholder='Question Title' required />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-12">
              <label>Answer *</label>
              <Editor
                toolbar={
                  {
                    options: ['link', 'image']
                  }
                }
                editorState={this.state.editorStateAns}
                wrapperClassName="demo-wrapper"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                onEditorStateChange={this.onEditorStateAnsChange}
              />
            </div>
          </div>
          <span>If content not helpful, prompt user with the following options-</span>
          <div className='row'>
            <div className='col-sm-12'>
              {this.createUI()}
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-12 modal-input">
              <button type='button' onClick={this.addClick} className='btn btn-add-more'>Add more</button>
            </div>
            <br />
          </div>
        </form>
      </div>

    );
  }
}

AddForm.propTypes = {
  location: PropTypes.object.isRequired,
  addQuestionAction: PropTypes.func.isRequired,
  changeState: PropTypes.func.isRequired
}
AddForm.defaultProps = {
}
export default withRouter(connect(null, {
  addQuestionAction
})(AddForm))
