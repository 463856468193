import { Observable } from 'rxjs/Observable';
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';

import { apiCall } from '../../utils';
import { ERROR } from '../Status/logic';

export const queryOptions = ['query', 'comment', 'suggestion', 'bug']
export const statusOptions = ['resolved', 'pending']

/* Fetch QUERIES
action, epic reducers starts here --29rohitkumar01 */
const INITIAL_FETCH_QUERYDATA_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false
}
const FETCH_QUERYDATA = 'FETCH_QUERYDATA';
export const FETCH_QUERYDATA_SUCCESS = 'FETCH_QUERYDATA_SUCCESS';
const FETCH_QUERYDATA_FAILURE = 'FETCH_QUERYDATA_FAILURE';
// Fetch QueryData action
export const fetchQueryDataAction = payload => ({
  type: FETCH_QUERYDATA,
  payload
})
// Fetch QueryData Success action
export const fetchQueryDataSuccess = payload => ({
  type: FETCH_QUERYDATA_SUCCESS,
  payload
})
// Fetch QueryData epic
export const fetchQueryDataEpic = action$ => action$
  .ofType(FETCH_QUERYDATA)
  .mergeMap(action => staticAjax(apiCall(`${process.env.apiUrl}feedback/getfeedback?${action.payload}`, 'GET', true))
    .map(response => fetchQueryDataSuccess(response))
    .catch(error => Observable.of({
      type: FETCH_QUERYDATA_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))
  // fetch QueryData Reducer
export function fetchQueryDataReducer (state = INITIAL_FETCH_QUERYDATA_STATE, action = {}) {
  switch (action.type) {
    case FETCH_QUERYDATA: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case FETCH_QUERYDATA_SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        flag: true
      }
    }
    case FETCH_QUERYDATA_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
/* Fetch QUERIES
action, epic reducers ends here --29rohitkumar01 */


/* Add RESPONSE
action, epic reducers starts here --29rohitkumar01 */

// Add Response
const INITIAL_ADD_RESPONSE_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false
}
const ADD_RESPONSE = 'ADD_RESPONSE';
export const ADD_RESPONSE_SUCCESS = 'ADD_RESPONSE_SUCCESS';
const ADD_RESPONSE_FAILURE = 'ADD_RESPONSE_FAILURE';

// Add Response action
export const addResponseAction = payload => ({
  type: ADD_RESPONSE,
  payload
})

// Add Response Success action
export const addResponseSuccess = payload => ({
  type: ADD_RESPONSE_SUCCESS,
  payload
})

// Add Response epic
export const addResponseEpic = action$ => action$
  .ofType(ADD_RESPONSE)
  .mergeMap(action => staticAjax(apiCall(`${process.env.apiUrl}feedback/savereply`, 'POST', true, action.payload))
    .map(response => addResponseSuccess(response))
    .catch(error => Observable.of({
      type: ADD_RESPONSE_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

// Add Response Reducer
export function addResponseReducer (state = INITIAL_ADD_RESPONSE_STATE, action = {}) {
  switch (action.type) {
    case ADD_RESPONSE: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case ADD_RESPONSE_SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        flag: true
      }
    }
    case ADD_RESPONSE_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
/* ADD RESPONSE
action, epic reducers ends here --29rohitkumar01 */

