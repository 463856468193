/**
 * *********************************
 * Combining all epics
 * *********************************
 * add all new epics below
 * *********************************
 */

import { combineEpics } from 'redux-observable'
import { loginEpic, logoutEpic } from './Login/logic'
import { validateUserEpic } from './Validation/logic'
import { addQuestionEpic } from './ModalForm/logic'
import { fetchCategoriesEpic, addCategoriesEpic, deleteCategoryEpic } from './Sidebar/logic'
// import { fetchQuestionsEpic, deleteQuestionEpic } from './Question_List/logic';
import { addQueryEpic, fetchQueryListEpic, addUserResponseEpic } from './QueryForm/logic'
// import { fetchQueriesEpic, fetchUsernameEpic, addResponseEpic } from './Feedback_Manager/logic'
import { fetchQuestionEpic, editQuestionEpic } from './EditForm/logic'
// import { fetchQueriesEpic, fetchUsernameEpic } from './Feedback_Manager/logic'
import { fetchUsernameEpic, fetchOptionTypeEpic } from './Query_SideBar/logic'
import { fetchQueryDataEpic, addResponseEpic } from './QueryDisplayer/logic';
import { searchQuestionsEpic } from './SearchBar/logic'
import { deleteQuestionEpic, usefulQueCountEpic } from './QuestionAnswer/logic'
import { fetchQuestionsEpic } from './Question_Displayer/logic'
import { fetchAppsEpic } from './Dropdown/logic'


const epics = combineEpics(
  loginEpic,
  logoutEpic,
  addQuestionEpic,
  fetchCategoriesEpic,
  addCategoriesEpic,
  fetchQuestionsEpic,
  addQueryEpic,
  fetchQueryListEpic,
  // fetchQueriesEpic,
  fetchUsernameEpic,
  fetchQuestionEpic,
  editQuestionEpic,
  deleteQuestionEpic,
  usefulQueCountEpic,
  fetchOptionTypeEpic,
  // fetchStatusEpic,
  addResponseEpic,
  fetchQueryDataEpic,
  searchQuestionsEpic,
  deleteCategoryEpic,
  fetchAppsEpic,
  addUserResponseEpic,
  validateUserEpic
)

export default epics
