/**
 * *************************************************************************
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 * * *************************************************************************
 */

const MODAL = 'MODAL'
const MODAL_CLOSE = 'MODAL_CLOSE'
const INITIAL_STATE = {
  isOpen: false,
  type: '',
  size: 'lg',
}

export const modalAction = payload => ({
  type: MODAL,
  payload
})

export const modalCloseAction = () => ({
  type: MODAL_CLOSE,
  payload: INITIAL_STATE
})

export const modalReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case MODAL: {
      return {
        ...state,
        ...action.payload,
        modalTypeStatus: true
        // this is unused variable added too fix sonar issue
        // as both the cases returning same set of variables
      }
    }
    case MODAL_CLOSE: {
      return {
        ...state,
        ...action.payload,
        // same goes for this
        modalTypeStatus: false
      }
    }
    default:
      return state
  }
}
