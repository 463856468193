/**
 * *************************************************************************
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 * * *************************************************************************
 */

import React from 'react';
import { Button } from 'reactstrap';


const ConfirmDelete = (props) => {
  const close = props.returnResponse
    ? () => props.returnResponse(false)
    : null;
  const confirm = props.returnResponse
    ? () => props.returnResponse(true)
    : null
  return (
    <div className="confirm-modal-body">
      <p>
        Do you want to delete the question?
      </p>
      <div className="modal-input">
        <Button className="cancel-btn" color="" onClick={close}>Cancel</Button>
        <Button className="remove-btn" onClick={confirm}>Delete</Button>
      </div>
    </div>
  )
}

export default ConfirmDelete;
