/**
 * *********************************
 * Combining all reducers
 * *********************************
 * add all new reducers below
 * *********************************
 */


import { combineReducers } from 'redux'
import { authReducer } from './Login/logic'
import { validateUserReducer } from './Validation/logic'
import { statusReducer } from './Status/logic'
import { modalReducer } from './Modal/logic'
import { addQuestionReducer } from './ModalForm/logic'
import { fetchCategoriesReducer, addCategoriesReducer, deleteCategoryReducer } from './Sidebar/logic'
import { addQueryReducer, fetchQueryListReducer, addUserResponseReducer } from './QueryForm/logic'
import { fetchQuestionReducer, editQuestionReducer } from './EditForm/logic'
import { fetchUsernameReducer, fetchOptionTypeReducer } from './Query_SideBar/logic';
import { fetchQueryDataReducer, addResponseReducer } from './QueryDisplayer/logic';
import { searchQuestionsReducer } from './SearchBar/logic'
import { fetchQuestionsReducer } from './Question_Displayer/logic'
import { deleteQuestionReducer } from './QuestionAnswer/logic'
import { fetchAppsReducer } from './Dropdown/logic'


const reducers = combineReducers({
  auth: authReducer,
  status: statusReducer,
  modal: modalReducer,
  addQuestion: addQuestionReducer,
  categories: fetchCategoriesReducer,
  questions: fetchQuestionsReducer,
  addQuery: addQueryReducer,
  prevQueries: fetchQueryListReducer,
  username: fetchUsernameReducer,
  response: addResponseReducer,
  addCategories: addCategoriesReducer,
  question: fetchQuestionReducer,
  editQuestion: editQuestionReducer,
  deleteQuestion: deleteQuestionReducer,
  queryType: fetchOptionTypeReducer,
  // queryStatus: fetchStatusReducer,
  queryData: fetchQueryDataReducer,
  searchQuestions: searchQuestionsReducer,
  deleteCategory: deleteCategoryReducer,
  fetchApps: fetchAppsReducer,
  addUserResponse: addUserResponseReducer,
  validateUser: validateUserReducer
})

export default reducers
