/**
 * *************************************************************************
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 * * *************************************************************************
 */

import { Observable } from 'rxjs/Observable';
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import { apiCall } from '../../utils';
import { ERROR } from '../Status/logic';
// import { renderAnswer } from '../Question_List/index';
// import { fetchQuestionsAction } from '../Question_List/logic'

const INITIAL_FETCH_QUESTION_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false
}
const FETCH_QUESTION = 'FETCH_QUESTION';
export const FETCH_QUESTION_SUCCESS = 'FETCH_QUESTION_SUCCESS';
const FETCH_QUESTION_FAILURE = 'FETCH_QUESTION_FAILURE';
export const fetchQuestionAction = payload => ({
  type: FETCH_QUESTION,
  payload
})
export const fetchQuestionSuccess = payload => ({
  type: FETCH_QUESTION_SUCCESS,
  payload
})
// Fetch_question
export const fetchQuestionEpic = action$ => action$
  .ofType(FETCH_QUESTION)
  .mergeMap(action => staticAjax(apiCall(`${process.env.apiUrl}FAQ/getFaq?${action.payload}`, 'GET', true))
    .map(response => fetchQuestionSuccess(response))
    .catch(error => Observable.of({
      type: FETCH_QUESTION_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))
export function fetchQuestionReducer (state = INITIAL_FETCH_QUESTION_STATE, action = {}) {
  switch (action.type) {
    case FETCH_QUESTION: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case FETCH_QUESTION_SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        flag: true
      }
    }
    case FETCH_QUESTION_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
// edit Form
const INITIAL_EDIT_QUESTION_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false
}
const EDIT_QUESTION = 'EDIT_QUESTION';
export const EDIT_QUESTION_SUCCESS = 'EDIT_QUESTION_SUCCESS';
const EDIT_QUESTION_FAILURE = 'EDIT_QUESTION_FAILURE';

// EDIT_QUESTION action
export const editQuestionAction = payload => ({
  type: EDIT_QUESTION,
  payload
})

// EDIT_QUESTION Success action
export const editQuestionSuccess = payload => ({
  type: EDIT_QUESTION_SUCCESS,
  payload
})

// EDIT_QUESTION epic
export const editQuestionEpic = action$ => action$
  .ofType(EDIT_QUESTION)
  .mergeMap(action => staticAjax(apiCall(`${process.env.apiUrl}FAQ/createFaq`, 'POST', true, action.payload))
    .map(response => editQuestionSuccess(response))
    .catch(error => Observable.of({
      type: EDIT_QUESTION_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

  // EDIT_QUESTION Reducer
export function editQuestionReducer (state = INITIAL_EDIT_QUESTION_STATE, action = {}) {
  switch (action.type) {
    case EDIT_QUESTION: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case EDIT_QUESTION_SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        flag: true
      }
    }
    case EDIT_QUESTION_FAILURE: {
      return {
        ...state,
        data: {},
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}

