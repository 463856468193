/**
 * *************************************************************************
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 * * *************************************************************************
 */

import React, { Fragment } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { parse } from 'query-string'
import { getCookie, removeCookies } from '../../utils'
import { logoutAction } from '../../container/Login/logic'

const Header = (props) => {
  const parsed = parse(props.location.search)
  const permission = getCookie('fm_permissions') || [];
  if (getCookie('accessToken') && props.location.pathname !== '/queryform' && parsed.currentApp === 'FeedbackManager') {
    return (
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-sm-12 app-header'>
            <div className='brand-name'>
              <span className='blackish-color'>SUPPORT</span> <span className='yellow-color'> SERVICES</span>
            </div>
            <Fragment>
              {(permission.indexOf('admin') > -1 || permission.indexOf('guest') > -1) && parsed.currentApp === 'FeedbackManager' ?
                <div className={`widget ${props.location.pathname === '/dashboard' && 'active'}`}>
                  <Link to="/dashboard" >HOME</Link>
                </div> :
                null}
              { (permission.indexOf('admin') > -1 || permission.indexOf('guest') > -1) && parsed.currentApp === 'FeedbackManager' && parsed.app &&
                <Fragment>
                  <div className={`widget ${props.location.pathname === '/faq' && 'active'}`}>
                    <Link to={`/faq?app=${parsed.app}&currentApp=${parsed.currentApp}`} >FAQ AUTHORING</Link>
                  </div>
                  <div className={`widget ${props.location.pathname === '/resolve' && 'active'}`}>
                    <Link to={`/resolve?app=${parsed.app}&view=status&currentApp=${parsed.currentApp}`} >QUERY MANAGEMENT</Link>
                  </div>
                </Fragment>
              }
              <div className="logout-btn">
                <button className='primary-button' onClick={() => props.logoutAction('feedback')} >Logout</button>
              </div>
            </Fragment>
          </div>
        </div>
      </div>
    );
  }
  if (!getCookie('accessToken')) {
    removeCookies();
  }
  return null
}

Header.propTypes = {
  logoutAction: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
}
Header.defaultProps = {
}
export default withRouter(connect(null, { logoutAction })(Header))
