
/**
 * *********************************
 * Application adding routers and history
 * *********************************
 * will mount whole application in root file
 * *********************************
 */

import React, { Fragment } from 'react'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { createEpicMiddleware } from 'redux-observable'
import * as Sentry from '@sentry/browser';
import rootReducer from './container/reducers'
import rootEpic from './container/epics'
import Main from './container/Main'
import Status from './container/Status'
import ModalComponent from './container/Modal'
import { Header, ErrorBoundary } from './components'
// import Form from './components/DemoForm'

Sentry.init({
  dsn: process.env.sentryUrl
});

const epicMiddleware = createEpicMiddleware(rootEpic)
const store = createStore(rootReducer, applyMiddleware(epicMiddleware))

const Root = () => (
  <Provider store={store} >
    <Router>
      <Fragment>
        <ErrorBoundary>
          <Status />
          <ModalComponent />
          <Header />
          <Main />
        </ErrorBoundary>
        {/* <Form /> */}
      </Fragment>
    </Router>
  </Provider>
)

export default Root
