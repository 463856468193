/**
 * *************************************************************************
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 * * *************************************************************************
 */

import React, { Component } from 'react'
import * as Sentry from '@sentry/browser';

// should have been called before using it here
// ideally before even rendering your react app

export default class SentryErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, eventId: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({
        eventId
      })
    });
  }

  render() {
    if (this.state.error) {
      // render fallback UI
      return (
        <div role="presentation" onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>Report feedback</div>
      );
    }
    return this.props.children;
  }
}
