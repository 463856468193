/**
 * *************************************************************************
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 * * *************************************************************************
 */

import { Observable } from 'rxjs/Observable';
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import { apiCall } from '../../utils';
import { ERROR } from '../Status/logic';

const INITIAL_VALIDATE_USER_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false
}
const VALIDATE_USER = 'VALIDATE_USER';
export const VALIDATE_USER_SUCCESS = 'VALIDATE_USER_SUCCESS';
const VALIDATE_USER_FAILURE = 'VALIDATE_USER_FAILURE';


export const validateUserAction = payload => ({
  type: VALIDATE_USER,
  payload
})
export const validateUserSuccess = payload => ({
  type: VALIDATE_USER_SUCCESS,
  payload
})
// Fetch_question
export const validateUserEpic = action$ => action$
  .ofType(VALIDATE_USER)
  .mergeMap(() => staticAjax(apiCall(`${process.env.mlabApi}users?${process.env.apiKey}`, 'GET', false))
    .map(response => validateUserSuccess(response))
    .catch(error => Observable.of({
      type: VALIDATE_USER_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))
export function validateUserReducer (state = INITIAL_VALIDATE_USER_STATE, action = {}) {
  switch (action.type) {
    case VALIDATE_USER: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case VALIDATE_USER_SUCCESS: {
      // Commented the section for the bypass the validation
      // setCookie(action.payload.response[0].accessToken, 'accessToken')
      // setCookie(JSON.stringify(['client']), 'permissions')
      return {
        ...state,
        data: action.payload.response[0],
        loading: false,
        error: false,
        flag: true
      }
    }
    case VALIDATE_USER_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
