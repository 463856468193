/**
 * *************************************************************************
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 * * *************************************************************************
 */

import { Observable } from 'rxjs/Observable';
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import { apiCall } from '../../utils';
import { ERROR } from '../Status/logic';

const INITIAL_FETCH_QUESTIONS_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false
}
const FETCH_QUESTIONS = 'FETCH_QUESTIONS';
export const FETCH_QUESTIONS_SUCCESS = 'FETCH_QUESTIONS_SUCCESS';
const FETCH_QUESTIONS_FAILURE = 'FETCH_QUESTIONS_FAILURE';

// Fetch Questions action
export const fetchQuestionsAction = payload => ({
  type: FETCH_QUESTIONS,
  payload
})

// Fetch Questions Success action
export const fetchQuestionsSuccess = payload => ({
  type: FETCH_QUESTIONS_SUCCESS,
  payload
})

// Fetch Questions epic
export const fetchQuestionsEpic = action$ => action$
  .ofType(FETCH_QUESTIONS)
  .mergeMap(action => staticAjax(apiCall(`${process.env.apiUrl}FAQ/getQuestions?${action.payload}`, 'GET', true))
    .map(response => fetchQuestionsSuccess(response))
    .catch(error => Observable.of({
      type: FETCH_QUESTIONS_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

// fetch Questions Reducer
export function fetchQuestionsReducer (state = INITIAL_FETCH_QUESTIONS_STATE, action = {}) {
  switch (action.type) {
    case FETCH_QUESTIONS: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case FETCH_QUESTIONS_SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        flag: true
      }
    }
    case FETCH_QUESTIONS_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
