/**
 * *************************************************************************
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 * * *************************************************************************
 */

import React, { Component } from 'react'
import { getCookie } from '../../utils'

const AlternateView = () => (<div> You Dont have permission </div>)

const Access = (allowedPermissions = []) => BaseComponent =>
  class Permissions extends Component {
    constructor (props) {
      super(props)
      this.state = {
        allowedPermissions,
        all: getCookie('fm_permissions') ? getCookie('fm_permissions') : []
      }
      this.checkPermission = this.checkPermission.bind(this)
    }
    checkPermission () {
      let access = true
      for (let i = 0; i < allowedPermissions.length; i += 1) {
        access = access && (this.state.all.indexOf(allowedPermissions[i]) > -1)
      }
      return access
    }
    render () {
      if (this.checkPermission()) {
        return <BaseComponent {...this.props} />
      }
      return <AlternateView />
    }
  }

export default Access
