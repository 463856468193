/**
 * *************************************************************************
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 * * *************************************************************************
 */
import { Observable } from 'rxjs/Observable';
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import { apiCall } from '../../utils';
import { ERROR } from '../Status/logic';

const INITIAL_DELETE_QUESTION_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false
}
const USEFUL_QUESTION = 'USEFUL_QUESTION';
const DELETE_QUESTION = 'DELETE_QUESTION';
export const DELETE_QUESTION_SUCCESS = 'DELETE_QUESTION_SUCCESS';
const DELETE_QUESTION_FAILURE = 'DELETE_QUESTION_FAILURE';

// DELETE_QUESTION action
export const deleteQuestionAction = payload => ({
  type: DELETE_QUESTION,
  payload
})

// DELETE_QUESTION Success action
export const deleteQuestionSuccess = payload => ({
  type: DELETE_QUESTION_SUCCESS,
  payload
})

// DELETE_QUESTION epic
export const deleteQuestionEpic = action$ => action$
  .ofType(DELETE_QUESTION)
  .mergeMap(action => staticAjax(apiCall(`${process.env.apiUrl}FAQ/deleteQuestion?${action.payload}`, 'GET', true))
    .map(response => deleteQuestionSuccess(response))
    .catch(error => Observable.of({
      type: DELETE_QUESTION_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

// DELETE_QUESTION Reducer
export function deleteQuestionReducer (state = INITIAL_DELETE_QUESTION_STATE, action = {}) {
  switch (action.type) {
    case DELETE_QUESTION: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case DELETE_QUESTION_SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        flag: true
      }
    }
    case DELETE_QUESTION_FAILURE: {
      return {
        ...state,
        data: {},
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}

// Useful Question Count Increase
export const usefulQueCountAction = payload => ({
  type: USEFUL_QUESTION,
  payload
})

// Useful Question Count epic
export const usefulQueCountEpic = action$ => action$
  .ofType(USEFUL_QUESTION)
  .mergeMap(action => staticAjax(apiCall(`${process.env.apiUrl}FAQ/updateResponse`, 'PUT', true, action.payload)))
