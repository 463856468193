/**
 * *************************************************************************
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 * * *************************************************************************
 */

import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import { apiCall } from '../../utils'
import { ERROR } from '../Status/logic'

const INITIAL_FETCH_CATEGORIES_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false
}
const FETCH_CATEGORIES = 'FETCH_CATEGORIES'
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS'
const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE'

// Fetch Categories action
export const fetchCategoriesAction = payload => ({
  type: FETCH_CATEGORIES,
  payload
})

// Fetch Categories Success action
export const fetchCategoriesSuccess = payload => ({
  type: FETCH_CATEGORIES_SUCCESS,
  payload
})

// Fetch Categories epic
export const fetchCategoriesEpic = action$ => action$
  .ofType(FETCH_CATEGORIES)
  .mergeMap(action => staticAjax(apiCall(`${process.env.apiUrl}FAQ/getCategory?${action.payload}`, 'GET', true))
    .map(response => fetchCategoriesSuccess(response))
    .catch(error => Observable.of({
      type: FETCH_CATEGORIES_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

// fetch Categories Reducer
export function fetchCategoriesReducer (state = INITIAL_FETCH_CATEGORIES_STATE, action = {}) {
  switch (action.type) {
    case FETCH_CATEGORIES: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case FETCH_CATEGORIES_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true
      }
    }
    case FETCH_CATEGORIES_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}

// Add category

const INITIAL_ADD_CATEGORIES_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false
}
const ADD_CATEGORIES = 'ADD_CATEGORIES'
export const ADD_CATEGORIES_SUCCESS = 'ADD_CATEGORIES_SUCCESS'
const ADD_CATEGORIES_FAILURE = 'ADD_CATEGORIES_FAILURE'
// Add Category action
export const addCategoriesAction = payload => ({
  type: ADD_CATEGORIES,
  payload
})
// Add Category Success action
export const addCategoriesSuccess = payload => ({
  type: ADD_CATEGORIES_SUCCESS,
  payload
})
// Add Category epic
export const addCategoriesEpic = action$ => action$
  .ofType(ADD_CATEGORIES)
  .mergeMap(action => staticAjax(apiCall(`${process.env.apiUrl}FAQ/createCategory`, 'POST', true, action.payload))
    .map(response => addCategoriesSuccess(response))
    .catch(error => Observable.of({
      type: ADD_CATEGORIES_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

  // Add Category Reducer
export function addCategoriesReducer (state = INITIAL_ADD_CATEGORIES_STATE, action = {}) {
  switch (action.type) {
    case ADD_CATEGORIES: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case ADD_CATEGORIES_SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        flag: true
      }
    }
    case ADD_CATEGORIES_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}

const INITIAL_DELETE_CATEGORY_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false
}
const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
const DELETE_CATEGORY_FAILURE = 'DELETE_CATEGORY_FAILURE';

// DELETE_CATEGORY action
export const deleteCategoryAction = payload => ({
  type: DELETE_CATEGORY,
  payload
})

// DELETE_CATEGORY Success action
export const deleteCategorySuccess = payload => ({
  type: DELETE_CATEGORY_SUCCESS,
  payload
})

// DELETE_CATEGORY epic
export const deleteCategoryEpic = action$ => action$
  .ofType(DELETE_CATEGORY)
  .mergeMap(action => staticAjax(apiCall(`${process.env.apiUrl}FAQ/deleteCategory?${action.payload}`, 'GET', true))
    .map(response => deleteCategorySuccess(response))
    .catch(error => Observable.of({
      type: DELETE_CATEGORY_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

// DELETE_CATEGORY Reducer
export function deleteCategoryReducer (state = INITIAL_DELETE_CATEGORY_STATE, action = {}) {
  switch (action.type) {
    case DELETE_CATEGORY: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case DELETE_CATEGORY_SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        flag: true
      }
    }
    case DELETE_CATEGORY_FAILURE: {
      return {
        ...state,
        data: {},
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
