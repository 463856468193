/**
 * *************************************************************************
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 * * *************************************************************************
 */

import React, { Component } from 'react'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import { parse } from 'query-string';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import draftToHtml from 'draftjs-to-html';
import { stateFromHTML } from 'draft-js-import-html';
import { makeId } from '../../utils';
// import ReactHtmlParser from 'react-html-parser';
import { editQuestionAction } from './logic'

class EditForm extends Component {
  constructor (props) {
    super(props)
    const parsed = parse(this.props.location.search);
    this.state = {
      app: parsed.app || '',
      cat: parsed.cat || '',
      subcat: parsed.subcat || '',
      currentQuestion: parsed.ques || '',
      editorState: EditorState.createEmpty(),
      quest: '',
      values: []
    };
    this.handleChangeQuestion = this.handleChangeQuestion.bind(this);
    this.addClick = this.addClick.bind(this);
    this.removeClick = this.removeClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  // componentDidMount() {
  //   console.log(this.props.question.data)
  // }
  componentWillMount() {
    if (this.props.question.data.length > 0) {
      const contentState = stateFromHTML(this.props.question.data[0].answer);
      const editorStateAns = EditorState.createWithContent(contentState);
      this.setState({
        quest: this.props.question.data[0].question,
        values: this.props.question.data[0].feedback,
        editorState: editorStateAns
      })
    }
  }
  // if (this.props.editQuestion !== this.props.editQuestion &&
  // Object.keys(this.props.editQuestion).length > 0) {
  //   const q = `&q={"id":"${this.state.currentQuestion}"}`
  //   this.props.fetchQuestionAction(q);
  // }
  onEditorStateAnsChange= (editorState) => {
    this.setState({
      editorState,
    });
  };
  handleChangeQuestion(event) {
    this.setState({ quest: event.target.value });
  }
  addClick() {
    this.setState(prevState => ({ values: [...prevState.values, ''] }))
  }
  removeClick(i) {
    const values = [...this.state.values];
    values.splice(i, 1);
    this.setState({ values });
  }
  handleSubmit(event) {
    event.preventDefault();
    const body = {
      app: this.state.app,
      category: this.state.cat,
      subcategory: this.state.subcat,
      parent_id: `${this.state.subcat}` ? `${makeId(this.state.app, this.state.cat, this.state.subcat)}` : `${makeId(this.state.app, this.state.cat)}`,
      ques_id: this.state.currentQuestion,
      question: this.state.quest,
      answer: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())),
      feedback: this.state.values
    }
    this.props.editQuestionAction(body);
    this.props.changeState('editQuestionForm', false)
  }
  handleChange(i, event) {
    const values = [...this.state.values];
    values[i] = event.target.value;
    this.setState({ values });
  }
  createUI() {
    return this.state.values.map((el, i) =>
      (
        <div key={i} className="input-group modal-input">
          <input type="text" value={el || ''} onChange={e => this.handleChange(i, e)} className="form-control" placeholder='Enter here' required />
          <div className='input-group-btn'>
            <button className="btn remove-btn" onClick={() => this.removeClick(i)}>X</button>
          </div>
        </div>
      ))
  }
  render() {
    return (
      <div className='edit-question-form'>
        <form onSubmit={this.handleSubmit}>
          <input type='hidden' value={this.state.app} className="form-control" required />
          <input type='hidden' value={this.state.cat} className="form-control" required />
          {this.state.subcat &&
          <input type='hidden' value={this.state.subcat} className="form-control" required />
          }
          <div className="row">
            <div className="form-group col-md-12 modal-input">
              <input type="submit" value="SAVE" className="primary-button right" />
              <button className='cancel-btn right btn-margin' onClick={() => this.props.changeState('editQuestionForm', false)} >Cancel</button>
              <label>Question</label>
              <input type="text" value={this.state.quest} onChange={this.handleChangeQuestion} className="form-control" placeholder='Question Title' required />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-12">
              <label>Answer</label>
              <Editor
                toolbar={
                  {
                    options: ['link', 'image']
                  }
                }
                editorState={this.state.editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                onEditorStateChange={this.onEditorStateAnsChange}
              />
            </div>
          </div>
          <span>If content not helpful, prompt user with the following options-</span>
          <div className='row'>
            <div className='col-sm-12'>
              {this.createUI()}
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-12 modal-input">
              <button type='button' onClick={this.addClick} className='btn btn-add-more'>Add more</button>
            </div>
          </div>
        </form>
      </div>

    );
  }
}
function MapStateToProps(state) {
  return {
    question: state.question
  };
}
EditForm.propTypes = {
  question: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  editQuestionAction: PropTypes.func.isRequired,
  changeState: PropTypes.func.isRequired
  // editQuestion: PropTypes.object.isRequired
}
EditForm.defaultProps = {
}
export default
withRouter(connect(MapStateToProps, {
  editQuestionAction
})(EditForm));
