/**
 * *************************************************************************
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 * * *************************************************************************
 */

import { Observable } from 'rxjs/Observable';
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import { apiCall } from '../../utils'
import { ERROR } from '../Status/logic'

const INITIAL_SEARCH_QUESTIONS_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false
}
const SEARCH_QUESTIONS = 'SEARCH_QUESTIONS';
export const SEARCH_QUESTIONS_SUCCESS = 'SEARCH_QUESTIONS_SUCCESS';
const SEARCH_QUESTIONS_FAILURE = 'SEARCH_QUESTIONS_FAILURE';

// SEARCH Questions action
export const searchQuestionsAction = payload => ({
  type: SEARCH_QUESTIONS,
  payload
})

// SEARCH Questions Success action
export const searchQuestionsSuccess = payload => ({
  type: SEARCH_QUESTIONS_SUCCESS,
  payload
})

// SEARCH Questions epic
export const searchQuestionsEpic = action$ => action$
  .ofType(SEARCH_QUESTIONS)
  .mergeMap(action => staticAjax(apiCall(`${process.env.apiUrl}FAQ/searchFaq?${action.payload}`, 'GET', true))
    .map(response => searchQuestionsSuccess(response))
    .catch(error => Observable.of({
      type: SEARCH_QUESTIONS_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

// SEARCH Questions Reducer
export function searchQuestionsReducer(state = INITIAL_SEARCH_QUESTIONS_STATE, action = {}) {
  switch (action.type) {
    case SEARCH_QUESTIONS: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case SEARCH_QUESTIONS_SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        flag: true
      }
    }
    case SEARCH_QUESTIONS_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
