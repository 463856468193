/**
 * *************************************************************************
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 * * *************************************************************************
 */
import React from 'react'

const Premium = () => (
  <div>
    hello
  </div>
)

export default Premium
