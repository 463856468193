/**
 * *************************************************************************
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 * * *************************************************************************
 */

import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import { apiCall } from '../../utils'
import { ERROR } from '../Status/logic'

const INITIAL_ADD_QUESTION_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false
}
const ADD_QUESTION = 'ADD_QUESTION'
export const ADD_QUESTION_SUCCESS = 'ADD_QUESTION_SUCCESS'
const ADD_QUESTION_FAILURE = 'ADD_QUESTION_FAILURE'

// Add Questions action
export const addQuestionAction = payload => ({
  type: ADD_QUESTION,
  payload
})
// Add Questions Success action
export const addQuestionSuccess = payload => ({
  type: ADD_QUESTION_SUCCESS,
  payload
})
// Add Questions epic
export const addQuestionEpic = action$ => action$
  .ofType(ADD_QUESTION)
  .mergeMap(action => staticAjax(apiCall(`${process.env.apiUrl}FAQ/createFaq`, 'POST', true, action.payload))
    .map(response => addQuestionSuccess(response))
    .catch(error => Observable.of({
      type: ADD_QUESTION_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))
// fetch Categories Reducer
export function addQuestionReducer (state = INITIAL_ADD_QUESTION_STATE, action = {}) {
  switch (action.type) {
    case ADD_QUESTION: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case ADD_QUESTION_SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        flag: true
      }
    }
    case ADD_QUESTION_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
