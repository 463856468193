/**
 * *************************************************************************
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 * * *************************************************************************
 */

import React, { Component } from 'react'
// import { Modal } from 'reusable-react-components'
import { Modal } from 'reactstrap';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { modalCloseAction } from '../Modal/logic'
import AddForm from '../ModalForm'
import EditForm from '../EditForm'
import ConfirmDelete from '../../components/ConfirmDelete'

class ModalComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      there: ''
    }
    // this.toggle = this.toggle.bind(this);
  }
  // toggle() {
  //   this.props.modalCloseAction();
  // }
  renderType () {
    if (this.props.modal.type === 'addForm') {
      return <AddForm />
    } else if (this.props.modal.type === 'editForm') {
      return <EditForm />
    } else if (this.props.modal.type === 'confirm-delete') {
      const returnFun = (resp) => {
        if (this.props.modal.returnResponse) {
          this.props.modal.returnResponse(resp)
        }
        this.props.modalCloseAction({})
      }
      return <ConfirmDelete returnResponse={returnFun} />
    }
    return null
  }
  render () {
    const {
      isOpen, size
    } = this.props.modal
    return (
      <Modal
        isOpen={isOpen}
        // toggle={this.toggle}
        onClosed={this.props.modalCloseAction}
        size={size}
      >
        {this.state.there}
        {this.renderType()}
      </Modal>
    )
  }
}
ModalComponent.propTypes = {
  modal: PropTypes.shape({
    isOpen: PropTypes.bool.isRequired,
    size: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  }).isRequired,
  modalCloseAction: PropTypes.func.isRequired
}
ModalComponent.defaultProps = {
}

const mapStateToProps = state => ({
  modal: state.modal
})

export default connect(mapStateToProps, { modalCloseAction })(ModalComponent)
