/**
 * *************************************************************************
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 * * *************************************************************************
 */

import { Observable } from 'rxjs/Observable';
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import { apiCall } from '../../utils';
import { ERROR } from '../../container/Status/logic';

const INITIAL_FETCH_APPS_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false
}
const FETCH_APPS = 'FETCH_APPS';
export const FETCH_APPS_SUCCESS = 'FETCH_APPS_SUCCESS';
const FETCH_APPS_FAILURE = 'FETCH_APPS_FAILURE';
export const fetchAppsAction = payload => ({
  type: FETCH_APPS,
  payload
})
export const fetchAppsSuccess = payload => ({
  type: FETCH_APPS_SUCCESS,
  payload
})
// Fetch Apps
export const fetchAppsEpic = action$ => action$
  .ofType(FETCH_APPS)
  .mergeMap(() => staticAjax(apiCall(`${process.env.apiUrl}FAQ/getApps`, 'GET', true))
    .map(response => fetchAppsSuccess(response))
    .catch(error => Observable.of({
      type: FETCH_APPS_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))
export function fetchAppsReducer (state = INITIAL_FETCH_APPS_STATE, action = {}) {
  switch (action.type) {
    case FETCH_APPS: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case FETCH_APPS_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true
      }
    }
    case FETCH_APPS_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
