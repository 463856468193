/**
 * *************************************************************************
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 * * *************************************************************************
 */

import React from 'react'
import PropTypes from 'prop-types'

const NoDataFound = props => (
  <div>{props.message || 'No Data Found'}</div>
)

NoDataFound.propTypes = {
  message: PropTypes.string
}
NoDataFound.defaultProps = {
  message: ''
}

export default NoDataFound
