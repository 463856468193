/**
 * *************************************************************************
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 * * *************************************************************************
 */

import React from 'react'
import PropTypes from 'prop-types'

const Loader = ({ height }) => (
  <div className='loader' style={{ height }}>
    <div className='spinner-new'>
      <div className='bounce1' />
      <div className='bounce2' />
      <div className='bounce3' />
    </div>
  </div>
)

Loader.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

Loader.defaultProps = {
  height: '100vh'
}

export default Loader
