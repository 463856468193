/**
 * *************************************************************************
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 * * *************************************************************************
 */

import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import { ERROR } from '../Status/logic'
import { apiCall } from '../../utils'


const INITIAL_FETCH_QUERY_LIST_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false
}
const FETCH_QUERY_LIST = 'FETCH_QUERY_LIST'
export const FETCH_QUERY_LIST_SUCCESS = 'FETCH_QUERY_LIST_SUCCESS'
const FETCH_QUERY_LIST_FAILURE = 'FETCH_QUERY_LIST_FAILURE'
// Fetch Previous Query List action
export const fetchQueryListAction = payload => ({
  type: FETCH_QUERY_LIST,
  payload
})
// Fetch Previous Query List Success action
export const fetchQueryListSuccess = payload => ({
  type: FETCH_QUERY_LIST_SUCCESS,
  payload
})
// Fetch Previous Query List epic
export const fetchQueryListEpic = action$ => action$
  .ofType(FETCH_QUERY_LIST)
  .mergeMap(action => staticAjax(apiCall(`${process.env.apiUrl}feedback/getfeedback?${action.payload}`, 'GET', true))
    .map(response => fetchQueryListSuccess(response))
    .catch(error => Observable.of({
      type: FETCH_QUERY_LIST_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))
  // Fetch Previous Query List Reducer
export function fetchQueryListReducer (state = INITIAL_FETCH_QUERY_LIST_STATE, action = {}) {
  switch (action.type) {
    case FETCH_QUERY_LIST: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case FETCH_QUERY_LIST_SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        flag: true
      }
    }
    case FETCH_QUERY_LIST_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}

// Add Queries
const INITIAL_ADD_QUERY_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false
}
const ADD_QUERY = 'ADD_QUERY'
export const ADD_QUERY_SUCCESS = 'ADD_QUERY_SUCCESS'
const ADD_QUERY_FAILURE = 'ADD_QUERY_FAILURE'
// Add Query action
export const addQueryAction = payload => ({
  type: ADD_QUERY,
  payload
})
// Add Query Success action
export const addQuerySuccess = payload => ({
  type: ADD_QUERY_SUCCESS,
  payload
})
// Add Query epic
export const addQueryEpic = action$ => action$
  .ofType(ADD_QUERY)
  .mergeMap(action => staticAjax(apiCall(`${process.env.apiUrl}feedback/savefeedback`, 'POST', true, action.payload))
    .map(response => addQuerySuccess(response))
    .catch(error => Observable.of({
      type: ADD_QUERY_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))
  // Add Queries Reducer
export function addQueryReducer (state = INITIAL_ADD_QUERY_STATE, action = {}) {
  switch (action.type) {
    case ADD_QUERY: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case ADD_QUERY_SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        flag: true
      }
    }
    case ADD_QUERY_FAILURE: {
      return {
        ...state,
        data: {},
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}


// Add User Response
const INITIAL_ADD_USER_RESPONSE_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false
}
const ADD_USER_RESPONSE = 'ADD_USER_RESPONSE';
export const ADD_USER_RESPONSE_SUCCESS = 'ADD_USER_RESPONSE_SUCCESS';
const ADD_USER_RESPONSE_FAILURE = 'ADD_USER_RESPONSE_FAILURE';

// Add User Response action
export const addUserResponseAction = payload => ({
  type: ADD_USER_RESPONSE,
  payload
})

// Add User Response Success action
export const addUserResponseSuccess = payload => ({
  type: ADD_USER_RESPONSE_SUCCESS,
  payload
})

// Add User Response epic
export const addUserResponseEpic = action$ => action$
  .ofType(ADD_USER_RESPONSE)
  .mergeMap(action => staticAjax(apiCall(`${process.env.apiUrl}feedback/userresponse`, 'POST', true, action.payload))
    .map(response => addUserResponseSuccess(response))
    .catch(error => Observable.of({
      type: ADD_USER_RESPONSE_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

// Add User Response Reducer
export function addUserResponseReducer (state = INITIAL_ADD_USER_RESPONSE_STATE, action = {}) {
  switch (action.type) {
    case ADD_USER_RESPONSE: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case ADD_USER_RESPONSE_SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        flag: true
      }
    }
    case ADD_USER_RESPONSE_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
