import { Observable } from 'rxjs/Observable';
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import { apiCall } from '../../utils';
import { ERROR } from '../Status/logic';


/* Fetch USERNAME action, epic reducers start here --29rohitkumar01 */
const INITIAL_FETCH_USERNAME_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false
}
const FETCH_USERNAME = 'FETCH_USERNAME';
export const FETCH_USERNAME_SUCCESS = 'FETCH_USERNAME_SUCCESS';
const FETCH_USERNAME_FAILURE = 'FETCH_USERNAME_FAILURE';
// Fetch Username action
export const fetchUsernameAction = payload => ({
  type: FETCH_USERNAME,
  payload
})
// Fetch Username Success action
export const fetchUsernameSuccess = payload => ({
  type: FETCH_USERNAME_SUCCESS,
  payload
})
// Fetch Username epic
export const fetchUsernameEpic = action$ => action$
  .ofType(FETCH_USERNAME)
  .mergeMap(action => staticAjax(apiCall(`${process.env.apiUrl}feedback/users?${action.payload}`, 'GET', true))
    .map(response => fetchUsernameSuccess(response))
    .catch(error => Observable.of({
      type: FETCH_USERNAME_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))
  // fetch Username Reducer
export function fetchUsernameReducer (state = INITIAL_FETCH_USERNAME_STATE, action = {}) {
  switch (action.type) {
    case FETCH_USERNAME: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case FETCH_USERNAME_SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        flag: true
      }
    }
    case FETCH_USERNAME_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
/* Fetch USERNAME action, epic reducers ends here --29rohitkumar01 */


/* Fetch USERNAMES on search
action, epic reducers starts here --29rohitkumar01 */

// search Username
const INITIAL_SEARCH_USERNAME_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false
}
const SEARCH_USERNAME = 'SEARCH_USERNAME';
export const SEARCH_USERNAME_SUCCESS = 'SEARCH_USERNAME_SUCCESS';
const SEARCH_USERNAME_FAILURE = 'SEARCH_USERNAME_FAILURE';

// search Username action
export const searchUsernameAction = payload => ({
  type: SEARCH_USERNAME,
  payload
})

// search Username Success action
export const searchUsernameSuccess = payload => ({
  type: SEARCH_USERNAME_SUCCESS,
  payload
})

// search Username epic
export const searchUsernameEpic = action$ => action$
  .ofType(SEARCH_USERNAME)
  .mergeMap(action => staticAjax(apiCall(`${process.env.mlabApi}queries?${process.env.apiKey}${action.payload.q}`, 'PUT', false, action.payload.body))
    .map(() => searchUsernameSuccess(action.payload.states))
    .catch(error => Observable.of({
      type: SEARCH_USERNAME_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

// search Username Reducer
export function searchUsernameReducer (state = INITIAL_SEARCH_USERNAME_STATE, action = {}) {
  switch (action.type) {
    case SEARCH_USERNAME: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case SEARCH_USERNAME_SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        flag: true
      }
    }
    case SEARCH_USERNAME_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}

/* Fetch USERNAMES
action, epic reducers ends here --29rohitkumar01 */


/* Fetch QUERY OPTION TYPES -(feedback,query,request,complain)
action, epic reducers start here --29rohitkumar01 */
const INITIAL_FETCH_OPTION_TYPE_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false
}
const FETCH_OPTION_TYPE = 'FETCH_OPTION_TYPE'
export const FETCH_OPTION_TYPE_SUCCESS = 'FETCH_OPTION_TYPE_SUCCESS'
const FETCH_OPTION_TYPE_FAILURE = 'FETCH_OPTION_TYPE_FAILURE'

// Fetch Query Option Type action
export const fetchOptionTypeAction = payload => ({
  type: FETCH_OPTION_TYPE,
  payload
})

// Fetch Query Option Type Success action
export const fetchOptionTypeSuccess = payload => ({
  type: FETCH_OPTION_TYPE_SUCCESS,
  payload
})

// Fetch Query Option Type epic
export const fetchOptionTypeEpic = action$ => action$
  .ofType(FETCH_OPTION_TYPE)
  .mergeMap(action => staticAjax(apiCall(`${process.env.mlabApi}products?${process.env.apiKey}${action.payload}`, 'GET', false))
    .map(response => fetchOptionTypeSuccess(response))
    .catch(error => Observable.of({
      type: FETCH_OPTION_TYPE_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

// Fetch Query Option Type Reducer
export function fetchOptionTypeReducer (state = INITIAL_FETCH_OPTION_TYPE_STATE, action = {}) {
  switch (action.type) {
    case FETCH_OPTION_TYPE: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case FETCH_OPTION_TYPE_SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        flag: true
      }
    }
    case FETCH_OPTION_TYPE_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
/* Fetch QUERY OPTION TYPES -(feedback,query,request,complain)
action, epic reducers end here --29rohitkumar01 */


/* Fetch QUERY STATUS -(Resolved, Unresolved, In Progress)
action, epic reducers start here --29rohitkumar01 */


// const INITIAL_FETCH_STATUS_STATE = {
//   data: [],
//   loading: false,
//   error: false,
//   flag: false
// }
// const FETCH_STATUS = 'FETCH_STATUS'
// export const FETCH_STATUS_SUCCESS = 'FETCH_STATUS_SUCCESS'
// const FETCH_STATUS_FAILURE = 'FETCH_STATUS_FAILURE'

// // Fetch Query Status Type action
// export const fetchStatusAction = payload => ({
//   type: FETCH_STATUS,
//   payload
// })

// // Fetch Query Status Type Success action
// export const fetchStatusSuccess = payload => ({
//   type: FETCH_STATUS_SUCCESS,
//   payload
// })

// // Fetch Query Status Type epic
// export const fetchStatusEpic = action$ => action$
//   .ofType(FETCH_STATUS)
// .mergeMap(action => staticAjax(apiCall(`${process.env.mlabApi}
//  products?${process.env.apiKey}&${action.payload}`, 'GET', false))
//     .map(response => fetchStatusSuccess(response))
//     .catch(error => Observable.of({
//       type: FETCH_STATUS_FAILURE,
//       payload: error
//     }, {
//       type: ERROR,
//       payload: error
//     })))

// // Fetch Query Status Type Reducer
// export function fetchStatusReducer (state = INITIAL_FETCH_STATUS_STATE, action = {}) {
//   switch (action.type) {
//     case FETCH_STATUS: {
//       return {
//         ...state,
//         data: [],
//         loading: true,
//         error: false,
//         flag: false
//       }
//     }
//     case FETCH_STATUS_SUCCESS: {
//       return {
//         ...state,
//         data: action.payload.response,
//         loading: false,
//         error: false,
//         flag: true
//       }
//     }
//     case FETCH_STATUS_FAILURE: {
//       return {
//         ...state,
//         data: [],
//         loading: false,
//         error: true,
//         flag: false
//       }
//     }
//     default:
//       return state
//   }
// }
