/**
 * *************************************************************************
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 * * *************************************************************************
 */

import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Loadable from 'react-loadable'
import { Loader } from '../../components'

const Validator = Loadable({
  loader: () => import('../Validation'),
  loading() {
    return <Loader />
  }
})

const QueryResolution = Loadable({
  loader: () => import('../QueryResolution'),
  loading() {
    return <Loader />
  }
})

const DashboardPage = Loadable({
  loader: () => import('../Dashboard'),
  loading() {
    return <Loader />
  }
})

const FAQPage = Loadable({
  loader: () => import('../FAQ'),
  loading() {
    return <Loader />
  }
})

const LoginPage = Loadable({
  loader: () => import('../Login'),
  loading() {
    return <Loader />
  }
})

const QueryForm = Loadable({
  loader: () => import('../QueryForm'),
  loading() {
    return <Loader />
  }
})

const Main = () => (
  <Switch>
    <Route exact path='/' component={LoginPage} />
    <Route exact path='/dashboard' component={DashboardPage} />
    <Route exact path='/faq' component={FAQPage} />
    <Route exact path='/queryform' component={QueryForm} />
    <Route exact path='/resolve' component={QueryResolution} />
    <Route exact path='/validate' component={Validator} />
  </Switch>
)

export default Main
